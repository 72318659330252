import pageheader from ".././../common/pageheader";
import { mapGetters } from "vuex";
import utils from "../../../utils/utils";
import appsheettoolsService from "../../../api/appsheettools.service";
import companyapi from "@/api/company.service";
import moment from "moment";
import UploadDialog from "../../secured/SupportToolsDialogs/UploadDialog";
import rules from "../../../utils/rules";
import EventBus from "../../../utils/EventBus";
import  * as exportService from "./export.service";

export default {
  name: "activationservices",
  components: { pageheader,UploadDialog },
  props: [],
  data() {
    return {
      importedItems: [
        { title: 'Upload File' },
        { title: 'Download Template' }
      ],
      deleteItems: [
        { title: 'Delete All' }
      ],
      selectedCompanyNameForDelete: "",
      isDeleteAll: false,
      isLoading: false,
      dates:[],
      menu:false,
      menu1:false,
      menu2:false,
      menu3:false,
      menu4:false,
      files: [],
      showUpload: false,
      fileLoader: false,
      fromDateMenu:false,
      toDateMenu:false,
      fromDate:"",
      fromDateFormatted: "",
      fromDateCommitted: "",
      fromDateCommittedFormatted: "",
      nowDate: moment().subtract(0, "days").format("YYYY-MM-DD"),
      rules: rules,
      toDate:new Date().toISOString().substr(0, 10),
      toDateFormatted: this.formatDateNew(new Date().toISOString().substr(0, 10)),
      toDateCommitted: new Date().toISOString().substr(0, 10),
      toDateCommittedFormatted: this.formatDateNew(new Date().toISOString().substr(0, 10)),
      isToDateCommittedShowMask: true,
      isFromDateCommittedShowMask: true,
      isEditInstallationDateShowMask: true,
      isDefaultInstallationDateShowMask: true,
      isFromShowMask: true,
      isToShowMask: true,
      panel: [0],
      committedList:[],
      totalServicesList:[],
      CountArray:[],
      input: {},
      showCommitButton: false,
      unCommittedRowsCount: 0,
      singleSelect: false,
      headers: [
        { text: "Action", value: "action", sortable: false, width: "85px" },
        { text: "Customer", value: "companyName", align: "left", width: "150px" },
        { text: "Machine Serial", value: "vin", align: "left", width: "150px" },
        {
          text: "TU Serial",
          value: "tuSerial",
          align: "left",
          width: "110px"
        },
        { text: "Manufacturer Name", value: "brand", align: "left", width: "130px" },
        { text: "Model", value: "model", align: "left", width: "110px" },
        { text: "Machine Type", value: "machineType", align: "left", width: "130px" },
        { text: "Year", value: "year", align: "center", width: "80px" },
        {
          text: "Installation Date",
          value: "installationDate",
          align: "center",
          width: "110px",
          class:`main-header table-header-padding`
        },
        {
          text: "PO / External Reference",
          value: "manufacturerReferenceId",
          align: "center",
          width: "110px",
          class:`main-header table-header-padding`
        },

        { 
          text: "Asset Name",
          value: "assetName",
          align: "left",
          width: "120px",
          class:`main-header table-header-padding`
        },
        {
          text: "Engine Hours",
          value: "engineHours",
          align: "center",
          width: "90px",
          class:`main-header table-header-padding`
        },
        {
          text: "Installer Notes",
          value: "notes",
          align: "center",
          width: "125px",
          class:`main-header table-header-padding`,
          sortable: false
        },
        {
          text: "Created on / Created By",
          value: "createdOn",
          align: "left",
          width: "105px"
        },
        {
          text: "Status",
          value: "isCommitted",
          align: "center",
          width: "80px"
        }
      ],
      committedHeaders: [
        { text: "Customer", value: "companyName", align: "left", width: "160px" },
        { text: "Machine Serial", value: "vin", align: "left", width: "160px" },
        {
          text: "TU Serial",
          value: "tuSerial",
          align: "left",
          width: "100px"
        },
        { text: "Manufacturer Name", value: "brand", align: "left", width: "130px" },
        { text: "Model", value: "model", align: "left", width: "110px" },
        { text: "Machine Type", value: "machineType", align: "left", width: "130px" },
        { text: "Year", value: "year", align: "center", width: "80px" },
        {
          text: "Installation Date",
          value: "installationDate",
          align: "center",
          width: "110px",
          class:`main-header table-header-padding`
        },
        { 
          text: "Asset Name",
          value: "assetName",
          align: "left",
          width: "120px",
          class:`main-header table-header-padding`
        },
        {
          text: "Engine Hours",
          value: "engineHours",
          align: "center",
          width: "90px",
          class:`main-header table-header-padding`
        },
        {
          text: "PO / External Reference",
          value: "manufacturerReferenceId",
          align: "center",
          width: "110px",
          class:`main-header table-header-padding`
        },
        {
          text: "Installer Notes",
          value: "notes",
          align: "center",
          width: "125px",
          class:`main-header table-header-padding`,
          sortable: false
        },
        {
          text: "Created on / Created By",
          value: "createdOn",
          align: "left",
          width: "105px"
        },
        {
          text: "Status",
          value: "isCommitted",
          align: "center",
          width: "80px"
        }
      ],
      page: 1,
      search: "",
      searchCommitted: "",
      statusList: [
        { name: "All", displayName: "All" },
        // { name: "Committed", displayName: "Committed" },
        // { name: "NonCommitted", displayName: "Non Committed" },
        { name: "Warning", displayName: "Warning" },
        { name: "Error", displayName: "Error" }
      ],
      selectedStatus: "All",
      companiesList: [],
      companiesListWithAll: [],
      selectedCompanyNonCommitted: "",
      selectedCompanyCommitted: "",
      selectedCompany: "",
      selectedCompanyName: "",
      servicesList: [],
      servicesListCommitted: [],
      selectedServices: [],
      selectAll: false,
      options: {},
      optionsCommitted: {},
      loadingServices: false,
      loadingCommittedServices: false,
      role: "",
      companyID: null,
      deleteConfirmationDialog: false,
      isdeletingService: false,
      commitConfirmationDialog: false,
      isCommitingService: false,
      deleteItem: null,
      deleteNonCommittedList: [],
      deleteNonCommittedListIds: [],
      editInstallationDateFormatted: "",
      editItem: {
        id: null,
        companyId: null,
        companyName: null,
        serial: null,
        vin: null,
        manufacturerReferenceId: null,
        tuSerial: null,
        // manufacturer: null,
        oem: null,
        assetName: null,
        model: null,
        year: null,
        machineType: null,
        brand: null,
        engineHours: null,
        installationDate: null,
        notes: null,
        messageType: null,
        message: null
      },
      isAddService: false,
      showAddServiceDialog: false,
      currentManufacturer: null,
      defaultInstallationDateFormatted: this.formatDateNew(new Date().toISOString().substr(0, 10)),
      defaultItemObj: {
        serial: 1,
        vin: "",
        manufacturerReferenceId: "",
        companyId: "",
        companyName: "",
        tuSerial: "",
        // manufacturer: "",
        oem: "",
        assetName: "",
        model: "",
        year: new Date().getFullYear(),
        machineType: "",
        brand: "",
        engineHours: 0,
        installationDate: new Date().toISOString().substr(0, 10),
        notes: "",
        messageType: "",
        message: "",
        isCommitted: false,
        operation: "Add"
      },
      defaultItem: Object.assign({}, this.defaultItemObj),
      isVINValid: false,
      isVINValidated: false,
      machineTypesList: [],
      brandsList: [],
      installationDateMenuAdd: false,
      installationDateMenuEdit: false,
      checkVINMessageDialog: false,
      checkingVIN: false,
      checkingEditVIN: false,
      checkingTUSerial: false,
      checkingEditTUSerial: false,
      isAddingService: false,
      isUpdatingService: false,
      oemCRID: this.$store.getters.oemCompanyId,
      userId: this.$store.getters.userId,
      vinDialogKey: null,
      masterCompaniesList: [],
      masterServicesList: [],
      validRecordMessage:
        "Valid record. <br>There is no asset in the system with the entered VIN. System will create a new asset and service.",
      exporting: false
    };
  },
  computed: {
    ...mapGetters(["roles", "reportId", "name", "loggedInReferenceId","oemCRIds"]),
    dateRangeText () {
      let tempDates = [];
      tempDates = [...this.dates];
      if (Date.parse(tempDates[1]) < Date.parse(tempDates[0])) {
              let temp = tempDates[1];
              tempDates[1] = tempDates[0]
              tempDates[0] = temp
      }
      tempDates[0] = moment(tempDates[0]).format('MM/DD/YYYY');
      tempDates[1] = moment(tempDates[1]).format('MM/DD/YYYY');
      return tempDates.join(' - ');
    },
    validateEngineHoursRules() {
      if (this.defaultItem.engineHours) {
        return this.rules.engineHoursRules;
      } else {
        return [true];
      }
    },
    validateAssetNameRules() {
      if (this.defaultItem.assetName) {
        return this.rules.assetNameRules;
      } else {
        return [true];
      }
    },
    validateRefRules() {
      if (this.defaultItem.manufacturerReferenceId) {
        return this.rules.refRules;
      } else {
        return [false];
      }
    },
    validateNotesRules() {
      if (this.defaultItem.notes) {
        return this.rules.notesRules;
      } else {
        return [true];
      }
    },
    validateEngineHoursRulesEdit() {
      if (this.editItem.engineHours) {
        return this.rules.engineHoursRules;
      } else {
        return [true];
      }
    },
    validateAssetNameRulesEdit() {
      if (this.editItem.assetName) {
        return this.rules.assetNameRules;
      } else {
        return [true];
      }
    },
    validateRefRulesEdit() {
      if (this.editItem.manufacturerReferenceId) {
        return this.rules.refRules;
      } else {
        return [true];
      }
    },
    validateNotesRulesEdit() {
      if (this.editItem.notes) {
        return this.rules.notesRules;
      } else {
        return [true];
      }
    },
    nonCommittedServices() {
      return this.servicesList.map(item => ({ ...item, isSelectable: !item.isCommitted && item.operation !='Add' && item.messageType !='ERROR' && item.messageType !='WARNING' }));
    }
  },
  mounted() {
    this.input = utils.getInputContent("activationservices", this.roles);
    var currentTime = new Date();
    currentTime.setDate(currentTime.getDate() - 29);
    this.fromDate = currentTime.toISOString().slice(0, 10);
    this.fromDateFormatted = this.formatDateNew(this.fromDate);
    this.toDate = new Date().toISOString().slice(0, 10);
    this.toDateFormatted = this.formatDateNew(this.toDate);
    this.fromDateCommitted = currentTime.toISOString().slice(0, 10);
    this.fromDateCommittedFormatted = this.formatDateNew(this.fromDateCommitted);
    this.toDateCommitted = new Date().toISOString().slice(0, 10);
    this.toDateCommittedFormatted = this.formatDateNew(this.toDateCommitted);
    // this.dates.push(this.fromDate,new Date().toISOString().slice(0, 10));
    this.dates.push(this.fromDate,this.toDate);
    this.loadDropdowns();
    this.loadCompaniesList();
  },
  watch: {
    fromDate (val) {
      this.fromDateFormatted = this.formatDateNew(val)
      this.isFromShowMask = false;
      setTimeout(() => {
        this.isFromShowMask = true;
      }, 1);
    },
    toDate (val) {
      this.toDateFormatted = this.formatDateNew(val)
      this.isToShowMask = false;
      setTimeout(() => {
        this.isToShowMask = true;
      }, 1);
    },
    'defaultItem.installationDate' (val) {
      this.defaultInstallationDateFormatted = this.formatDateNew(val)
      this.isDefaultInstallationDateShowMask = false;
      setTimeout(() => {
        this.isDefaultInstallationDateShowMask = true;
      }, 1);
    },
    'editItem.installationDate' (val) {
      this.editInstallationDateFormatted = this.formatDateNew(val)
      this.isEditInstallationDateShowMask = false;
      setTimeout(() => {
        this.isEditInstallationDateShowMask = true;
      }, 1);
    },
    fromDateCommitted (val) {
      this.fromDateCommittedFormatted = this.formatDateNew(val)
      this.isFromDateCommittedShowMask = false;
      setTimeout(() => {
        this.isFromDateCommittedShowMask = true;
      }, 1);
    },
    toDateCommitted (val) {
      this.toDateCommittedFormatted = this.formatDateNew(val)
      this.isToDateCommittedShowMask = false;
      setTimeout(() => {
        this.isToDateCommittedShowMask = true;
      }, 1);
    },
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        const format = "MM-DD-YYYY";
        return moment(value).format(format);
      }
    },
    formatDates: function (value) {
      if (value) {
        const format = "MM/DD/YYYY";
        return moment(value).format(format);
      }
    }
  },
  methods: {
    formatDateNew (date) {
      if (!date) return ""

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate (date, key) {
      if (!date) return ""

      const [month, day, year] = date.split('/')
      if (!month || month<1 || month>12 || !day || day<1 || day>31 || !year || year<2000 || year>2100) {
        if (key == "fromDate") {
          this.fromDate = "";
          this.fromDateFormatted = "";
          return ""
        } else if (key == "toDate") {
          this.toDate = "";
          this.toDateFormatted = "";
          return ""
        } else if (key == "defaultInstallationDateFormatted") {
          this.defaultItem.installationDate = "";
          this.defaultInstallationDateFormatted = "";
          return ""
        } else if (key == "editInstallationDateFormatted") {
          this.editItem.installationDate = "";
          this.editInstallationDateFormatted = "";
          return ""
        } else if (key == "fromDateCommitted") {
          this.fromDateCommitted = "";
          this.fromDateCommittedFormatted = "";
          return ""
        } else if (key == "toDateCommitted") {
          this.toDateCommitted = "";
          this.toDateCommittedFormatted = "";
          return ""
        }
      } else {
        let isFutureDate = moment(moment(date,'MM/DD/YYYY').format('YYYY-MM-DD')).isAfter(moment().add(0, 'days').utc().format('YYYY-MM-DD'));
        if (key == "defaultInstallationDateFormatted" && isFutureDate) {
          this.defaultItem.installationDate = "";
          this.defaultInstallationDateFormatted = "";
          return ""
        } else if (key == "editInstallationDateFormatted" && isFutureDate) {
          this.editItem.installationDate = "";
          this.editInstallationDateFormatted = "";
          return ""
        } else {
          return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        }
      }
    },
    // * Upload Activation servcies File Methods * //
    openUploadDialog() {
      this.files = [];
      this.$refs.uploadModal.showModal();
    },
    closeUploadDialog() {
      this.$refs.uploadModal.closeUploadDialog();
    },
    getList(files){
      this.files = [];
      this.files = files;
    },
    async UploadFile(selectedCompany){
      if (selectedCompany) {
        // alert("Upload a Sheet");
        this.fileLoader = true;
        let payload = {
          companyId: selectedCompany,
          // createdBy: this.userId,
          createdBy: this.name,
          fileInfo: this.files[0],
        };
        if (this.files[0] == null) {
          this.fileLoader = false;
          this.$toast.error("Please select a file ");
        } else if (!this.files[0].fileName.includes(".xlsx")) {
          this.fileLoader = false;
          this.$refs.dropzone.removeAllFiles();
          this.$toast.error("please select only files with extension .xlsx");
        } else {
          try {
            this.$toast.warning("File is being processed. Please wait.");
            // this.closeUploadDialog();
            let apiResult = await appsheettoolsService.UploadActivationServcieFile(
              this.oemCRID,
              payload,
            );
            
            if (apiResult.isSuccess == true) {
              this.fileLoader = false;
              this.$toast.success(apiResult.systemMessage);
              this.closeUploadDialog();
              this.loadServicesList();
              this.loadCommittedServicesList();
            }else{
              this.fileLoader = false;
              // this.closeUploadDialog();
              EventBus.$emit("upload-error-message", apiResult.systemMessage);
              this.$toast.error(apiResult.systemMessage);
            }
          } catch (err) {
            EventBus.$emit("upload-error-message", "Unable to upload file" + err);
            this.$toast.error("Unable to upload file" + err);
            this.fileLoader = false;
            // this.closeUploadDialog();
          }
        }
      } else {
        this.$toast.error("Please select the customer");
      }
      
    },
     // * Upload Activation servcies File Methods * //
    async loadCompaniesList() {
      try {
        let _this = this;
        if (this.masterCompaniesList.length == 0) {
          this.isLoading = true;
          let response = await companyapi.getAllCustomers();
          if (response) {
            _this.companiesList = response.data;
            if (this.roles == "User") {
              this.companiesList = response.data.filter(
                (c) => this.$store.getters.oemCRIds.includes(c.id)
              );
              _this.masterCompaniesList = this.companiesList;
            } else {
              _this.masterCompaniesList = response.data;
            }
            _this.companiesListWithAll = response.data && response.data.length > 0 ? JSON.parse(JSON.stringify(_this.companiesList)) : [];
            if (response.data && response.data.length>0) {
              let allCompanies = {
                id: "All",
                name: "All",
                oemCompanyName: "All"
              };
              _this.companiesListWithAll.unshift(allCompanies);
            }
            if (_this.$store.getters.reportId != "") {
              //set the dropdown value to the store reportID.
              _this.selectedCompany = _this.$store.getters.reportId;
              _this.selectedCompanyNonCommitted = _this.$store.getters.reportId;
              _this.selectedCompanyCommitted = _this.$store.getters.reportId;
              for (var i = 0; i < _this.companiesList.length; i++) {
                if (_this.companiesList[i].id ==  _this.selectedCompany) {
                  _this.selectedCompanyName = _this.companiesList[i].name;
                } 
              }
              _this.companyID = _this.$store.getters.reportId;

              //set the OEM Manufacturer of selected company
              _this.currentManufacturer = response.data.filter(item => {
                if (item.id == _this.$store.getters.reportId) {
                  return item;
                }
              })[0].oemCompanyName;
            } else if (response.data.length > 0) {
              //set the dropdown value to first item from the response.
              _this.selectedCompany = _this.companiesListWithAll[0].id;
              _this.selectedCompanyName = _this.companiesListWithAll[0].name;
              _this.companyID = _this.companiesListWithAll[0].id;
              _this.selectedCompanyNonCommitted = _this.companiesListWithAll[0].id;
              _this.selectedCompanyCommitted = _this.companiesListWithAll[0].id;

              //set the OEM Manufacturer of selected company
              _this.currentManufacturer = _this.companiesListWithAll[0].oemCompanyName;
            }
            //Load services on basis of company and status
            this.loadServicesList();
            this.loadCommittedServicesList();
          }
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        //this.$toast.error("Unable to load the data" + error);
      }
    },
    onCompanyChange() {
      if (this.selectedCompany) {
        let companyName = "";
        for (var i = 0; i < this.companiesList.length; i++) {
          if (this.companiesList[i].id ==  this.selectedCompany) {
            companyName = this.companiesList[i].name;
          } 
        }
        if (companyName) {
          this.selectedCompanyName = companyName;
        } else {
          this.selectedCompany = "";
          this.selectedCompanyName = ""
        }
      } else {
        this.selectedCompanyName = "";
      }
      let selectedCompanyRow = this.masterCompaniesList.filter(item => {
        if (item.id == this.selectedCompany) {
          return item;
        }
      })[0];
      this.companyID = selectedCompanyRow.id;

      //set the OEM Manufacturer of selected company
      this.currentManufacturer = selectedCompanyRow.oemCompanyName;
    },
    enableDisableSelectedDelete() {
      let showDeleteSelected = false;
      this.nonCommittedServices.map((s) => {
        if (s.canDelete) {
          showDeleteSelected = true;
        }
      })
      if (showDeleteSelected) {
        this.deleteItems = [
          { title: 'Delete Selected' },
          { title: 'Delete All' }
        ];
      } else {
        this.deleteItems = [
          { title: 'Delete All' }
        ];
      }
    },
    async loadServicesList() {
      if (this.selectedCompanyNonCommitted) {
        this.isVINValidated = false;
        this.CountArray = [];
        this.servicesList = [];
        this.totalServicesList = [];
        this.masterServicesList = [];
        //clear selected services
        this.selectedServices = [];
        this.cancelEditClick();
        if (this.fromDate && this.toDate) {
          if (Date.parse(this.fromDate) <= Date.parse(this.toDate)) {
            this.loadingServices = true;
            // this.fromDate = "";
            // this.toDate = "";
            // this.fromDate = this.dates[0];
            // this.toDate = this.dates[1];
            try {
              let companyFilterNonCommitted = "";
              if (this.roles == "User") {
                if (this.selectedCompanyNonCommitted == "All") {
                  companyFilterNonCommitted = this.companiesListWithAll.filter(item => item.id != 'All').map(function(c){
                    return c.id;
                  }).join(",");
                } else {
                  companyFilterNonCommitted = this.selectedCompanyNonCommitted;
                }

              } else {
                companyFilterNonCommitted = this.selectedCompanyNonCommitted;
              }
              let servicesResponse =
                await appsheettoolsService.getVendorActivationServicesNonCommitted(
                  companyFilterNonCommitted,
                  this.selectedStatus,
                  this.oemCRID,
                  this.fromDate,
                  this.toDate
                );

              //set the defaultItem manufacturer (changed this field to oem) based on company selected
              // this.defaultItemObj.manufacturer = this.currentManufacturer;
              this.defaultItemObj.oem = this.currentManufacturer;

              //reset the defaultItem
              this.defaultItem = Object.assign({}, this.defaultItemObj);
              this.defaultInstallationDateFormatted = this.formatDateNew(this.defaultItem.installationDate);
              this.isDefaultInstallationDateShowMask = false;
              setTimeout(() => {
                this.isDefaultInstallationDateShowMask = true;
              }, 1);

              if (
                servicesResponse &&
                servicesResponse.data &&
                servicesResponse.data.length > 0
              ) {
                this.servicesList = servicesResponse.data.map((item, index) => ({
                  ...item,
                  serial: index + 2,
                  canDelete: false
                }));

                this.totalServicesList = servicesResponse.data.map((item, index) => ({
                  ...item,
                  serial: index + 2,
                  canDelete: false
                }));

                //Add Summary Object
                let totalCommitted = this.servicesList.filter(item => item.isCommitted == true).length;
                // let totalNonCommitted = this.servicesList.filter(item => item.isCommitted == false).length;
                let totalValid = this.servicesList.filter(item => item.messageType == 'SUCCESS').length;
                let totalwarnings = this.servicesList.filter(item => item.messageType == 'WARNING').length;
                let totalErrors = this.servicesList.filter(item => item.messageType == 'ERROR').length;
                this.CountArray = [];
                let obj = {
                  totalRecordsName:"Total Records",
                  totalRecordsCount:this.servicesList.length,
                  committedName:"Committed",
                  committedCount: totalCommitted,
                  // nonCommittedName: "Non Committed",
                  // nonCommittedCount: totalNonCommitted,
                  validName: "Valid",
                  validCount: totalValid,
                  warningName:"Warnings",
                  warningCount:totalwarnings,
                  errorName:"Errors",
                  errorCount:totalErrors
                };
                this.CountArray.push(obj);

                // //add the default item at the first index of services list
                // this.servicesList.splice(0, 0, this.defaultItem);
                this.calculateUnCommitedCount();
                //backup the servicesList
                this.masterServicesList = [...this.servicesList];
              } else if (servicesResponse.data && servicesResponse.data.length == 0) {
                this.servicesList = [];

                // //add the default item at the first index of services list
                // this.servicesList.splice(0, 0, this.defaultItem);
                this.calculateUnCommitedCount();
                //backup the servicesList
                this.masterServicesList = [...this.servicesList];
              }
              this.loadingServices = false;

            
            } catch (err) {
              this.$toast.error("Unable to load the services data" + err);
              this.loadingServices = false;
            }
          } else {
            this.$toast.error("To date must be greater than or equal to From date ");
          }
          
        } else {
          this.$toast.error("Please select from date and to date.");
        }
      } else {
        this.$toast.error("Please select the customer.");
      }
    },
    async loadCommittedServicesList() {
      if (this.selectedCompanyCommitted) {
        this.servicesListCommitted = [];
        if (this.fromDateCommitted && this.toDateCommitted) {
          if (Date.parse(this.fromDateCommitted) <= Date.parse(this.toDateCommitted)) {
            this.loadingCommittedServices = true;
            try {
              let companyFilterCommitted = "";
              if (this.roles == "User") {
                if (this.selectedCompanyCommitted == "All") {
                  companyFilterCommitted = this.companiesListWithAll.filter(item => item.id != 'All').map(function(c){
                      return c.id;
                    }).join(",");
                } else {
                  companyFilterCommitted = this.selectedCompanyCommitted;
                }

              } else {
                companyFilterCommitted = this.selectedCompanyCommitted;
              }
              let servicesResponseCommitted =
                await appsheettoolsService.getVendorActivationServicesCommitted(
                  companyFilterCommitted,
                  this.oemCRID,
                  this.fromDateCommitted,
                  this.toDateCommitted
                );

              if (
                servicesResponseCommitted &&
                servicesResponseCommitted.data &&
                servicesResponseCommitted.data.length > 0
              ) {
                this.servicesListCommitted = servicesResponseCommitted.data.map((item, index) => ({
                  ...item,
                  serial: index + 1
                }));

              } else if (servicesResponseCommitted.data && servicesResponseCommitted.data.length == 0) {
                this.servicesListCommitted = [];
              }
              this.loadingCommittedServices = false;

            
            } catch (err) {
              this.$toast.error("Unable to load the committed services data" + err);
              this.loadingCommittedServices = false;
            }
          } else {
            this.$toast.error("To date must be greater than or equal to From date ");
          }
          
        } else {
          this.$toast.error("Please select from date and to date.");
        }
      } else {
        this.$toast.error("Please select the customer.");
      }
    },
    calculateUnCommitedCount() {
      this.unCommittedRowsCount = 0;
      this.servicesList.map(item => {
        if (!item.isCommitted) {
          this.unCommittedRowsCount++;
        }
      });
      // //reduce by one for the sake of default add row
      // this.unCommittedRowsCount = this.unCommittedRowsCount - 1;
    },

    confirmDelete(isDeleteAll) {
      this.deleteConfirmationDialog = true;
      this.isDeleteAll = isDeleteAll;
      this.deleteNonCommittedList = [];
      this.deleteNonCommittedListIds = [];
      // this.deleteItem = item;
      if (!isDeleteAll) {
        this.nonCommittedServices.map((s) => {
          if (s.canDelete) {
            this.deleteNonCommittedList.push(s.vin);
            this.deleteNonCommittedListIds.push(s.id);
          }
        })
      } else {
        this.nonCommittedServices.map((s) => {
          s.canDelete = true;
          this.enableDisableSelectedDelete();
        })
      }
    },
    closeDeleteConfirmationDialog() {
      this.deleteConfirmationDialog = false;
      if (this.isDeleteAll) {
        this.nonCommittedServices.map((s) => {
          s.canDelete = false;
          this.enableDisableSelectedDelete();
        })
      }
    },
    confirmCommit() {
      if (this.selectedServices.length > 0) {
        this.commitConfirmationDialog = true;
      } else {
        this.$toast.error(
          "Please select at least one service record to commit"
        );
      }
    },
    async deleteServiceClick() {
      if (this.selectedCompanyNonCommitted && this.selectedCompanyNonCommitted != "All") {
        this.isdeletingService = true;
        try {
          let payload = {
            companyId: this.selectedCompanyNonCommitted,
            records: this.deleteNonCommittedListIds.join(","),
            modifiedBy: this.name
          };
          let servicesResponse =
            await appsheettoolsService.deleteNonCommittedServices(
              payload
            );
          if (servicesResponse && servicesResponse.isSuccess) {
            this.$toast.success(servicesResponse.systemMessage);
            //hide the delete dialog
            this.deleteConfirmationDialog = false;
            //get latest data
            this.loadServicesList();
            this.loadCommittedServicesList();
          } else {
            this.$toast.error(servicesResponse.systemMessage);
          }
          this.isdeletingService = false;
        } catch (err) {
          this.$toast.error("Unable to delete the selected services " + err);
          this.isdeletingService = false;
        }
      } else {
        this.$toast.error("Please select specific customer. Can only delete one customer services at a time.")
      }
    },
    async deleteAllServiceClick() {
      if (this.selectedCompanyNonCommitted && this.selectedCompanyNonCommitted != "All") {
        this.isdeletingService = true;
        try {
          let payload = {
            companyId: this.selectedCompanyNonCommitted,
            status: this.selectedStatus,
            fromDate: this.fromDate,
            toDate: this.toDate,
            oemCrId: this.oemCRID,
            modifiedBy: this.name
          };
          let servicesResponse =
            await appsheettoolsService.deleteAllNonCommittedServices(
              payload
            );
          if (servicesResponse && servicesResponse.isSuccess) {
            this.$toast.success(servicesResponse.systemMessage);
            //hide the delete dialog
            this.deleteConfirmationDialog = false;
            //get latest data
            this.loadServicesList();
            this.loadCommittedServicesList();
          } else {
            this.$toast.error(servicesResponse.systemMessage);
          }
          this.isdeletingService = false;
        } catch (err) {
          this.$toast.error("Unable to delete the services " + err);
          this.isdeletingService = false;
        }
      } else {
        this.$toast.error("Please select specific customer. Can only delete one customer services at a time.")
      }
    },
    async commitServiceClick() {
      if (this.selectedCompanyNonCommitted && this.selectedCompanyNonCommitted != "All") {
        try {
          this.isCommitingService = true;
          let payload = [];
  
          //  Add selected service record IDs to payload
          this.selectedServices.map(item => {
            payload.push(item.id);
          });
          let servicesResponse =
            await appsheettoolsService.commitVendorActivationServices(
              this.oemCRID,
              this.selectedCompanyNonCommitted,
              payload,
              this.name
            );
          if (servicesResponse && servicesResponse.isSuccess) {
            this.$toast.success(servicesResponse.systemMessage);
            //hide the commit confirmation dialog
            this.commitConfirmationDialog = false;
  
            //get latest data
            this.loadServicesList();
            this.loadCommittedServicesList();
  
            //clear selected services
            this.selectedServices = [];
          } else {
            this.$toast.error(servicesResponse.systemMessage);
            this.commitConfirmationDialog = false;
            //get latest data
            this.loadServicesList();
            this.loadCommittedServicesList();
  
            //clear selected services
            this.selectedServices = [];
          }
          this.isCommitingService = false;
        } catch (err) {
          this.$toast.error("Unable to commit the services " + err);
          this.isCommitingService = false;
          this.commitConfirmationDialog = false;
          //get latest data
          this.loadServicesList();
          this.loadCommittedServicesList();
  
          //clear selected services
          this.selectedServices = [];
        }
      } else {
        this.$toast.error("Please select specific customer. Can only commit one customer services at a time.")
      }
      
    },
    editServiceClick(item) {
      this.editItem = Object.assign({}, item);
    },
    onAddServiceClick() {
      const addObj = Object.assign({}, this.defaultItem);
      addObj.operation = "Add";
      addObj.serial = this.servicesList.length + 1;
      this.servicesList.unshift(addObj);
    },
    onServiceAddedorUpdated() {
      this.loadServicesList();
      this.loadCommittedServicesList();
    },
    loadDropdowns() {
      this.getMachineTypes();
      this.getBrands();
    },
    async getMachineTypes() {
      try {
        let responseData =
          await appsheettoolsService.getMasterDataMachineTypes();
        if (responseData && responseData.length > 0) {
          this.machineTypesList = responseData;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getBrands() {
      try {
        let responseData =
          await appsheettoolsService.getMasterDataBrands();
        if (responseData && responseData.length > 0) {
          this.brandsList = responseData;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async checkVINID(flag, operation, item) {
      let paramVIN, paramTUSerial, companyId;
      if (operation == "add") {
        //add operation scenario

        if (flag == "vin") {
          this.checkingVIN = true;
        } else {
          this.checkingTUSerial = true;
        }

        if (this.defaultItem.vin == null || this.defaultItem.vin == undefined) {
          paramVIN = "";
        } else {
          paramVIN = this.defaultItem.vin;
        }
        if (
          this.defaultItem.tuSerial == null ||
          this.defaultItem.tuSerial == undefined
        ) {
          paramTUSerial = "";
        } else {
          paramTUSerial = this.defaultItem.tuSerial;
        }

        if (this.defaultItem.companyId) {
          companyId = this.defaultItem.companyId;
        } else {
          companyId = "";
        }
      } else {
        //edit operation scenario

        if (flag == "vin") {
          this.checkingEditVIN = true;
        } else {
          this.checkingEditTUSerial = true;
        }

        if (this.editItem.vin == null || this.editItem.vin == undefined) {
          paramVIN = "";
        } else {
          paramVIN = this.editItem.vin;
        }
        if (
          this.editItem.tuSerial == null ||
          this.editItem.tuSerial == undefined
        ) {
          paramTUSerial = "";
        } else {
          paramTUSerial = this.editItem.tuSerial;
        }
        if (this.editItem.companyId) {
          companyId = this.editItem.companyId;
        } else {
          companyId = "";
        }
      }
      if (companyId) {
        if (operation == "add" && !paramTUSerial && !paramVIN) {
          this.defaultItem.model = "";
          this.defaultItem.year = new Date().getFullYear();
          this.defaultItem.machineType = "";
          this.defaultItem.assetName = "";
          this.defaultItem.brand = "";
          this.defaultItem.engineHours = 0;
          this.defaultItem.notes = "";
          this.defaultItem.installationDate = new Date().toISOString().substr(0, 10);
          this.defaultItem.manufacturerReferenceId  = "";
          this.defaultItem.messageType = "";
          this.defaultItem.message = "";
          this.checkingVIN = false;
          this.checkingTUSerial = false;
          this.checkingEditVIN = false;
          this.checkingEditTUSerial = false;
        } else {
          try {
            let validateMachineResponse;
            if (flag == "vin") {
              validateMachineResponse =
                await appsheettoolsService.checkMachineIDIsValid(
                  this.oemCRID,
                  companyId,
                  paramVIN,
                  // ""
                  paramTUSerial
                );
            } else {
              validateMachineResponse =
                await appsheettoolsService.checkMachineIDIsValid(
                  this.oemCRID,
                  companyId,
                  // "",
                  paramVIN,
                  paramTUSerial
                );
            }
    
            if (
              validateMachineResponse &&
              validateMachineResponse.data &&
              validateMachineResponse.isSuccess
            ) {
    
              if (operation == "add") {
                if (flag == "vin") {
                  this.defaultItem.model = validateMachineResponse.data.model;
                  this.defaultItem.year = validateMachineResponse.data.year;
                  this.defaultItem.assetName = validateMachineResponse.data.assetName;
                  this.defaultItem.brand = validateMachineResponse.data.brand;
                  this.defaultItem.machineType =
                    validateMachineResponse.data.machineType;
                          // rama
                    this.defaultItem.tuSerial = validateMachineResponse.data.tuSerial ? validateMachineResponse.data.tuSerial : paramTUSerial;
                    this.defaultItem.engineHours  = validateMachineResponse.data.engineHours;
                    this.defaultItem.notes = validateMachineResponse.data.notes;
                    this.defaultItem.installationDate = validateMachineResponse.data.installationDate;
                    this.defaultItem.manufacturerReferenceId = validateMachineResponse.data.manufacturerReferenceId;
                } else {
                  this.defaultItem.model = validateMachineResponse.data.model;
                  this.defaultItem.year = validateMachineResponse.data.year;
                  this.defaultItem.assetName = validateMachineResponse.data.assetName;
                  this.defaultItem.brand = validateMachineResponse.data.brand;
                  this.defaultItem.machineType =
                    validateMachineResponse.data.machineType;
                  this.defaultItem.engineHours  = validateMachineResponse.data.engineHours;
                  this.defaultItem.notes = validateMachineResponse.data.notes;
                  this.defaultItem.installationDate = validateMachineResponse.data.installationDate;
                  this.defaultItem.manufacturerReferenceId = validateMachineResponse.data.manufacturerReferenceId;
                }
    
                if (validateMachineResponse.data.messageType) {
                  this.defaultItem.messageType =
                    validateMachineResponse.data.messageType;
                  this.defaultItem.message = validateMachineResponse.data.message;
                }
              } else {
                if (flag == "vin") {
                  this.editItem.model = validateMachineResponse.data.model;
                  this.editItem.year = validateMachineResponse.data.year;
                  this.editItem.assetName = validateMachineResponse.data.assetName;
                  this.editItem.brand = validateMachineResponse.data.brand;
                  this.editItem.machineType =
                    validateMachineResponse.data.machineType;
                          // rama
                    this.editItem.tuSerial = validateMachineResponse.data.tuSerial;
                    this.editItem.engineHours  = validateMachineResponse.data.engineHours;
                    this.editItem.notes = validateMachineResponse.data.notes;
                    this.editItem.installationDate = validateMachineResponse.data.installationDate;
                    this.editItem.manufacturerReferenceId = validateMachineResponse.data.manufacturerReferenceId;
                } else {
                  this.editItem.model = validateMachineResponse.data.model;
                  this.editItem.year = validateMachineResponse.data.year;
                  this.editItem.assetName = validateMachineResponse.data.assetName;
                  this.editItem.brand = validateMachineResponse.data.brand;
                  this.editItem.machineType =
                    validateMachineResponse.data.machineType;
                  this.editItem.engineHours  = validateMachineResponse.data.engineHours;
                  this.editItem.notes = validateMachineResponse.data.notes;
                  this.editItem.installationDate = validateMachineResponse.data.installationDate;
                  this.editItem.manufacturerReferenceId = validateMachineResponse.data.manufacturerReferenceId;
                }
    
                if (validateMachineResponse.data.messageType) {
                  item.messageType = validateMachineResponse.data.messageType;
                  item.message = validateMachineResponse.data.message;
                  //update the item in servicesList
                  this.servicesList.splice(item.serial - 2, 1, item);
                }
              }
            } else {
              ////reset the fields populated during validate functionality
              if (flag == "vin") {
                if (operation == "add") {
                  // this.defaultItem.tuSerial = "";
                  this.defaultItem.model = "";
                  this.defaultItem.year = "";
                  this.defaultItem.machineType = "";
                  this.defaultItem.assetName = "";
                  this.defaultItem.brand = "";
                  this.defaultItem.engineHours = "";
                  this.defaultItem.notes = "";
                  this.defaultItem.installationDate = "";
                  this.defaultItem.manufacturerReferenceId  = "";
                } else {
                  // this.editItem.tuSerial = "";
                  this.editItem.model = "";
                  this.editItem.year = "";
                  this.editItem.machineType = "";
                  this.editItem.assetName = "";
                  this.editItem.brand = "";
                  this.editItem.engineHours = "";
                  this.editItem.notes = "";
                  this.editItem.installationDate = "";
                  this.editItem.manufacturerReferenceId  = "";
                }
              } else {
                if (operation == "add") {
                  this.defaultItem.vin = "";
                  this.defaultItem.model = "";
                  this.defaultItem.year = "";
                  this.defaultItem.machineType = "";
                  this.defaultItem.assetName = "";
                  this.defaultItem.brand = "";
                  this.defaultItem.engineHours = "";
                  this.defaultItem.notes = "";
                  this.defaultItem.installationDate = "";
                  this.defaultItem.manufacturerReferenceId  = "";
                } else {
                  this.editItem.vin = "";
                  this.editItem.model = "";
                  this.editItem.year = "";
                  this.editItem.machineType = "";
                  this.editItem.assetName = "";
                  this.editItem.brand = "";
                  this.editItem.engineHours = "";
                  this.editItem.notes = "";
                  this.editItem.installationDate = "";
                  this.editItem.manufacturerReferenceId  = "";
                }
              }
    
              if (operation == "add") {
                this.defaultItem.model = "";
                this.defaultItem.year = new Date().getFullYear();
                this.defaultItem.machineType = "";
                this.defaultItem.assetName = "";
                this.defaultItem.brand = "";
                this.defaultItem.messageType = "SUCCESS";
                this.defaultItem.message = this.validRecordMessage;
            // rama
                this.defaultItem.engineHours  = "";
                this.defaultItem.notes = "";
                this.defaultItem.manufacturerReferenceId = "";
                this.defaultItem.installationDate = "";
    
              } else {
                this.editItem.model = "";
                this.editItem.year = new Date().getFullYear();
                this.editItem.machineType = "";
                this.editItem.assetName = "";
                this.editItem.brand = "";
                item.messageType = "SUCCESS";
                item.message = this.validRecordMessage;
    
          // rama
                this.editItem.engineHours  = "";
                this.editItem.notes = "";
                this.editItem.manufacturerReferenceId = "";
                this.editItem.installationDate = "";
    
                //update the item in servicesList
                this.servicesList.splice(item.serial - 2, 1, item);
              }
            }
    
            if (operation == "add") {
              //flag to confirm that Validation is done
              this.isVINValidated = true;
            }
            this.checkingVIN = false;
            this.checkingTUSerial = false;
            this.checkingEditVIN = false;
            this.checkingEditTUSerial = false;
          } catch (err) {
            //this.$toast.error("Unable to validate the VIN " + err);
            this.checkingVIN = false;
            this.checkingTUSerial = false;
            this.checkingEditVIN = false;
            this.checkingEditTUSerial = false;
          }
        }
      } else {
        this.$toast.error("Please select the customer");
        this.checkingVIN = false;
        this.checkingTUSerial = false;
        this.checkingEditVIN = false;
        this.checkingEditTUSerial = false;
      }
    },
    validateFields(operation) {
      let areAllFieldsValid = false;
      let returnObj = {
        isValid: areAllFieldsValid,
        errorMessage: ""
      };

      if (operation == "Add") {
        if (!this.defaultItem.companyId) {
          returnObj.errorMessage = "Customer is required";
        } else if (!this.defaultItem.vin) {
          returnObj.errorMessage = "VIN is required";
        } else if (this.defaultItem.vin && this.defaultItem.vin.length > 50) {
          returnObj.errorMessage = "VIN must be less than 50 characters";
        } else if (!this.defaultItem.tuSerial) {
          returnObj.errorMessage = "TU Serial is required";
        } else if (this.defaultItem.tuSerial && this.defaultItem.tuSerial.length > 10) {
          returnObj.errorMessage = "TU Serial must be less than 10 characters";
        }
        else if (!this.defaultItem.model) {
          returnObj.errorMessage = "Model is required";
        } else if (this.defaultItem.model && this.defaultItem.model.length > 100) {
          returnObj.errorMessage = "Model must be less than 100 characters";
        }
        else if (!this.defaultItem.installationDate) {
          returnObj.errorMessage = "Installation Date is required";
        } else if (!this.defaultItem.machineType) {
          returnObj.errorMessage = "Machine type is required";
        } else if (!this.defaultItem.brand) {
          returnObj.errorMessage = "Manufacturer Name is required";
        } else if (!this.defaultItem.year) {
          returnObj.errorMessage = "Year is required";
        } else if (this.defaultItem.year && !/^(?:[0-9]{4,4}|)$/.test(this.defaultItem.year)) {
          returnObj.errorMessage = "Please enter valid year";
        } else if (!this.defaultItem.manufacturerReferenceId) {
          returnObj.errorMessage = "PO / External Reference is required";
        } else if (this.defaultItem.notes && this.defaultItem.notes.length > 750) {
          returnObj.errorMessage = "Installer notes must be less than 750 characters";
        } else if (this.defaultItem.assetName && this.defaultItem.assetName.length > 100) {
          returnObj.errorMessage = "Asset name must be less than 100 characters";
        } else if (this.defaultItem.engineHours && this.defaultItem.engineHours.length > 12) {
          returnObj.errorMessage = "Engine hours must be less than 12 characters";
        }
        else {
          returnObj.errorMessage = "";
          returnObj.isValid = true;
        }
      } else {
        //handle edit fields scenario
        if (!this.editItem.companyId) {
          returnObj.errorMessage = "Customer is required";
        } else if (!this.editItem.vin) {
          returnObj.errorMessage = "VIN is required";
        } else if (this.editItem.vin && this.editItem.vin.length > 50) {
          returnObj.errorMessage = "VIN must be less than 50 characters";
        } else if (!this.editItem.tuSerial) {
          returnObj.errorMessage = "TU Serial is required";
        } else if (this.editItem.tuSerial && this.editItem.tuSerial.length > 10) {
          returnObj.errorMessage = "TU Serial must be less than 10 characters";
        }
        else if (!this.editItem.model) {
          returnObj.errorMessage = "Model is required";
        } else if (this.editItem.model && this.editItem.model.length > 100) {
          returnObj.errorMessage = "Model must be less than 100 characters";
        }
        else if (!this.editItem.installationDate) {
          returnObj.errorMessage = "Installation Date is required";
        } else if (!this.editItem.machineType) {
          returnObj.errorMessage = "Machine type is required";
        } else if (!this.editItem.brand) {
          returnObj.errorMessage = "Manufacturer Name is required";
        } else if (!this.editItem.year) {
          returnObj.errorMessage = "Year is required";
        } else if (this.editItem.year && !/^(?:[0-9]{4,4}|)$/.test(this.editItem.year)) {
          returnObj.errorMessage = "Please enter valid year";
        } else if (!this.editItem.manufacturerReferenceId) {
          returnObj.errorMessage = "PO / External Reference is required";
        } else if (this.editItem.notes && this.editItem.notes.length > 750) {
          returnObj.errorMessage = "Installer notes must be less than 750 characters";
        } else if (this.editItem.assetName && this.editItem.assetName.length > 100) {
          returnObj.errorMessage = "Asset name must be less than 100 characters";
        } else if (this.editItem.engineHours && this.editItem.engineHours.length > 12) {
          returnObj.errorMessage = "Engine hours must be less than 12 characters";
        } else {
          returnObj.errorMessage = "";
          returnObj.isValid = true;
        }
      }

      return returnObj;
    },
    async addServiceClick() {
      let validateResponse = this.validateFields("Add");

      if (validateResponse.isValid) {
        //call API to add item
        this.isAddingService = true;
        try {
          let addPayLoad = {
            vin: this.defaultItem.vin,
            tuSerial: this.defaultItem.tuSerial,
            manufacturerReferenceId: this.defaultItem.manufacturerReferenceId,
            model: this.defaultItem.model,
            machineType: this.defaultItem.machineType,
            assetName: this.defaultItem.assetName,
            brand: this.defaultItem.brand,
            year: this.defaultItem.year,
            engineHours: this.defaultItem.engineHours,
            notes: this.defaultItem.notes,
            installationDate: this.defaultItem.installationDate,
            createdBy: this.name
          };
          let addServiceResponse =
            await appsheettoolsService.createVendorActivationService(
              this.oemCRID,
              this.defaultItem.companyId,
              addPayLoad
            );
          if (addServiceResponse.isSuccess) {
            this.isAddingService = false;
            this.$toast.success(addServiceResponse.systemMessage);
            //reload services list
            this.loadServicesList();
            this.loadCommittedServicesList();
          } else {
            this.$toast.error(addServiceResponse.systemMessage);
          }
          this.isAddingService = false;
        } catch (err) {
          this.isAddingService = false;
          console.log(err);
        }
      } else {
        if (validateResponse.errorMessage) {
          this.$toast.error(validateResponse.errorMessage);
        }
      }
    },
    async updateServiceClick() {
      let validateResponse = this.validateFields("Edit");
      if (validateResponse.isValid) {
        this.isUpdatingService = true;
        try {
          let updateItemPayLoad = {
            vin: this.editItem.vin,
            manufacturerReferenceId: this.editItem.manufacturerReferenceId,
            tuSerial: this.editItem.tuSerial,
            model: this.editItem.model,
            machineType: this.editItem.machineType,
            assetName: this.editItem.assetName,
            brand: this.editItem.brand,
            year: this.editItem.year,
            engineHours: this.editItem.engineHours,
            notes: this.editItem.notes,
            installationDate: this.editItem.installationDate,
            modifiedBy: this.name
          };
          let updateResponse =
            await appsheettoolsService.updateVendorActivationService(
              this.oemCRID,
              this.editItem.companyId,
              this.editItem.id,
              updateItemPayLoad
            );
          if (updateResponse.isSuccess) {
            this.$toast.success(updateResponse.systemMessage);

            // //update the services list array with the item updated
            // let tempServices = this.masterServicesList.map(x => x);
            // tempServices.splice(this.editItem.serial - 1, 1, this.editItem);
            // this.servicesList = tempServices.map(x => x);

            //reload services list
            this.loadServicesList();
            this.loadCommittedServicesList();
            //disable edit mode
            this.cancelEditClick();
          } else {
            this.$toast.error(updateResponse.systemMessage);
          }
          this.isUpdatingService = false;
        } catch (err) {
          this.isUpdatingService = false;
          console.log(err);
        }
      } else {
        if (validateResponse.errorMessage) {
          this.$toast.error(validateResponse.errorMessage);
        }
      }
    },
    cancelEditClick() {
      this.servicesList = this.masterServicesList.map(x => x);
      this.editItem = {
        id: null,
        companyId: null,
        companyName: null,
        serial: null,
        vin: null,
        manufacturerReferenceId: null,
        tuSerial: null,
        model: null,
        year: null,
        machineType: null,
        assetName: null,
        brand: null,
        engineHours: null,
        installationDate: null,
        notes: null,
        messageType: null,
        message: null
      };
    },
    searchActivationServicesClick() {
      this.servicesList = [];
        this.loadServicesList();
    },
    searchCommittedActivationServicesClick() {
      this.servicesListCommitted = [];
        this.loadCommittedServicesList();
    },
    onNonCommittedFilterCompanyChange() {
      this.selectedCompanyNameForDelete = "";
      this.servicesList = [];
      this.companiesList.map((c) => {
        if (c.id == this.selectedCompanyNonCommitted) {
          this.selectedCompanyNameForDelete = c.name;
        }
      })
        this.loadServicesList();
    },
    onCommittedFilterCompanyChange() {
      this.servicesListCommitted = [];
        this.loadCommittedServicesList();
    },
    clearVINSearchFields() {
      // item.messageType = null;
      // item.message = null;
      this.defaultItem = Object.assign({}, this.defaultItemObj);
      this.defaultInstallationDateFormatted = this.formatDateNew(this.defaultItem.installationDate);
      this.isDefaultInstallationDateShowMask = false;
      setTimeout(() => {
        this.isDefaultInstallationDateShowMask = true;
      }, 1);
    },
    exportCommitted(){
      if ((this.servicesListCommitted?.length || 0) === 0) return
      this.exporting = true;
      exportService.ExportCommittedRecordsToExcl(this.servicesListCommitted);
      this.exporting = false;
    }
  }
};
